import { createSlice } from "@reduxjs/toolkit";
import { usersApi } from "../api/users";

export const usersSlice = createSlice({
  name: "usersSlice",
  initialState: {
    userNames: [],
    userPermission:null,
    userTeam:[]
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      usersApi.endpoints.getUsersNames.matchFulfilled,
      (state, { payload }) => {
        state.userNames = payload.users || [];
      }
    );

    builder.addMatcher(
      usersApi.endpoints.getUserPermission.matchFulfilled,
      (state, { payload }) => {
        state.userPermission = payload || {};
      }
    );

    builder.addMatcher(
      usersApi.endpoints.getUserTeamData.matchFulfilled,
      (state, { payload }) => {
        state.userTeam = payload?.candidateOwnerNames || [];
      }
    );
  },
});
