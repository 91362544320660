import {
  FormLabel,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import * as React from "react";

export default function FilterRadioButton({
  handleTopFilterChange,
  myJobValue,
  setMyJobsValue,
  radioLabel,
  radioValue,
  isDisableOnly = false,
}) {
  return (
    <FormControl className="table_box mb-3 p-2">
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        onChange={handleTopFilterChange}
        value={myJobValue}
      >
        <FormControlLabel
          value={radioValue}
          disabled={isDisableOnly}
          control={<Radio  className="d-none" />}
          label={radioLabel}
          className="m-0"
          style={{
            borderBottom:
              myJobValue !== "" ? "2px solid var(--main-bg-color)" : "none",
          }}
        />
      </RadioGroup>
    </FormControl>
  );
}
