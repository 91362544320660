import {
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import FilterListIcon from "@mui/icons-material/FilterList";
import React from "react";
import Select from "react-select";

const PopoverDropDownSearchInput = ({
  filedName,
  filterJobpostData,
  page,
  rowsPerPage,
  inputId,
  allUsers,
  searchOwnerName,
  setInputValue,
  isPopoverOpen,
  openPopover,
  setActivePopover,
  inputPlaceholder,
  setInputActiveValue,
  inputValue,
}) => {
  console.log("allUsers----------", allUsers);

  return (
    <React.Fragment>
      <Popover
        isOpen={isPopoverOpen(filedName)}
        onClose={() => setActivePopover(null)}
        isLazy
        placement={"auto"}
        size={"sm"}
        closeOnBlur={false}
      >
        <PopoverTrigger>
          <IconButton
            size="sm"
            icon={
              <FilterListIcon
                style={{
                  cursor: "pointer",
                  fontSize: "20px",
                }}
              />
            }
            onClick={() => openPopover(filedName)}
            style={{ boxShadow: "none", background: "#fff" }}
          />
        </PopoverTrigger>
        <PopoverContent
          sx={{ zIndex: 1 }}
          boxSize={"auto"}
          style={{ boxShadow: "none" }}
        >
          <PopoverArrow />
          <PopoverBody style={{ width: "250px" }}>
            <Select
              className="w-100"
              classNamePrefix="Select Owner Name"
              placeholder="Search Owner Name"
              isSearchable={true}
              id={inputId}
              options={allUsers.map((user) => ({
                // value: user?.accountName
                //   ? `${user?.accountName}`
                //   : `${user.firstName?.trim()} ${user.lastName?.trim()}`,
                value: user?._id,
                label: user?.accountName
                  ? `${user?.accountName}`
                  : `${user.firstName?.trim()} ${user.lastName?.trim()}`,
              }))}
              value={inputValue}
              onChange={(selectedOption) => {
                console.log('come===========');
                
                setInputValue(selectedOption);
                setInputActiveValue({
                  name: inputId,
                  value: selectedOption?.value,
                });
                filterJobpostData(
                  selectedOption?.value,
                  page,
                  rowsPerPage,
                  inputId,
                  true
                );
              }}
            />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </React.Fragment>
  );
};

export default PopoverDropDownSearchInput;
