import { DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Select,
  Switch,
} from "@chakra-ui/react";
import { userActivity } from "Utils";
import axios from "axios";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useHistory, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import config from "../../config";

const CreateForm = () => {
  const [cookie] = useCookies(["role", "id"]);
  const candidate_ownerName = localStorage?.getItem("candidate_ownerName");
  const userId = cookie.id;
  const userRole = cookie?.role;
  const history = useHistory();
  const [formFields, setFormFields] = useState([]);
  const [formData, setFormData] = useState({
    type: "",
    title: "",
    description: "",
    selectedField: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleAddField = () => {
    if (!formData?.selectedField) {
      toast.warning(`Please choose  Select Field.`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (formData.selectedField) {
      // Check if the selected field already exists in formFields
      const fieldExists = formFields.some(
        (field) => field.label === formData.selectedField
      );

      if (!fieldExists) {
        const fieldTypeMapping = {
          firstname: "text",
          lastname: "text",
          email: "email",
          phone: "number",
          designation: "text",
          aadharnumber: "number",
          pannumber: "text",
          address: "text",
          pincode: "number",
          file: "file",
          country: "text",
          state: "text",
          city: "text",
          experience: "number",
          Skill: "text",
          currentCandidateCTC: "text",
          expectedCandidateCTC: "text",
          noticePeriod: "text",
        };

        setFormFields([
          ...formFields,
          {
            label: formData.selectedField,
            type: fieldTypeMapping[formData.selectedField] || "text",
            name: formData.selectedField.toLowerCase(),
            isRequired: false,
          },
        ]);

        setFormData({
          ...formData,
          selectedField: "",
        });
      } else {
        toast.warning(
          `${formData.selectedField} field already exists. Please choose another field.`,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
    }
  };

  const handleRemoveField = (index) => {
    const updatedFields = [...formFields];
    updatedFields.splice(index, 1);
    setFormFields(updatedFields);
  };

  const generateLinkAndSaveForm = async () => {
    try {
      const ownerName = userId;
       

      const formConfig = {
        fields: formFields,
        type: formData.type,
        title: formData.title,
        description: formData.description,
        owner_name: ownerName,
      };

      let response;

      if (id) {
        response = await axios.put(
          `${config?.apiUrl}/api/editFormData/${id}`,
          formConfig
        );
        const activityOn = `Update form ${response?.data?.data?.title} `;
        const activityDo = "Update form";
        const component = "simpleform";
        userActivity(
          candidate_ownerName,
          userId,
          component,
          activityDo,
          activityOn,
          userRole
        );
      } else {
        response = await axios.post(
          `${config?.apiUrl}/api/createForm`,
          formConfig
        );
        console.log({ response });
        const activityOn = `Create form ${response?.data?.data?.title} `;
        const activityDo = "Create form";
        const component = "simpleform";
        userActivity(
          candidate_ownerName,
          userId,
          component,
          activityDo,
          activityOn,
          userRole
        );
      }

      if (response.data.status === "success") {
        history.push("/admin/simpleform");
        toast.success(response.data.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
      }
    } catch (error) {
      console.error("Error saving form:", error.message);
      toast.error(`Error saving form: ${error.message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    generateLinkAndSaveForm();
  };
  const handleSwitchChange = (index) => {
    const updatedFields = [...formFields];
    updatedFields[index].isRequired = !updatedFields[index].isRequired;
    setFormFields(updatedFields);
  };

  const { id } = useParams();

  useEffect(() => {
    const fetchFormData = async () => {
      try {
        const response = await axios.get(
          `${config?.apiUrl}/api/getFormDataById/${id}`
        );

        const { type, title, description, fields } = response.data;

        // Set the form data and pre-fill the form fields
        setFormData({
          type,
          title,
          description,
          selectedField: "",
        });

        // Map the fetched fields to the format used in formFields state
        const mappedFields = fields.map((field) => ({
          label: field.name,
          type: field.type,
          name: field.name,
          isRequired: field.isRequired,
        }));

        setFormFields(mappedFields);
      } catch (error) {
        console.error("Error fetching form data:", error.message);
      }
    };

    if (id) {
      fetchFormData();
    }
  }, [id]);

  const handleGoBack = () => {
    history?.goBack();
  };
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <Box pt={"10vh"}>
        <ToastContainer />
        <form onSubmit={handleSubmit}>
          <Box px={5} py={10} background={"#fdfdfd"} minHeight="86vh">
            <Box
              display={"flex"}
              flexDir={"column"}
              justifyContent={"center"}
              justifyItems={"center"}
              mx={"auto"}
              boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
              px={10}
              py={6}
              mb={10}
              bg={"white"}
            >
              <Box
                display={"flex"}
                gap={4}
                flexDir={["column", "column", "row", "row", "row"]}
              >
                <FormControl mb={4}>
                  <FormLabel>
                    Type <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <Select
                    name="type"
                    placeholder="Select Type"
                    onChange={handleInputChange}
                    value={formData.type}
                    isRequired
                  >
                    <option value="candidate">Candidate</option>
                    {/* <option value="contact">Contact</option> */}
                    {/* <option value="client">Client</option> */}
                  </Select>
                </FormControl>

                <FormControl mb={4}>
                  <FormLabel>
                    Select Field <span style={{ color: "red" }}>*</span>{" "}
                  </FormLabel>
                  <Select
                    name="selectedField"
                    placeholder="Select Field"
                    onChange={handleInputChange}
                    value={formData.selectedField}
                  >
                    <option value="firstname">First Name</option>
                    <option value="lastname">Last Name</option>
                    <option value="email">Email</option>
                    <option value="phone">Phone</option>
                    <option value="designation">Candidate Designation</option>
                    <option value="aadharnumber">Aadhar Card Number</option>
                    <option value="pannumber">PAN Card Number</option>
                    <option value="address">Address</option>
                    <option value="pincode">Pincode</option>
                    <option value="file">File upload</option>
                    <option value="experience">Years Of Experience</option>
                    <option value="country">Country</option>
                    <option value="city">City</option>
                    <option value="state">State</option>
                    <option value="skills">Skill</option>
                    <option value="currentCandidateCTC">
                      Current Candidate CTC
                    </option>
                    <option value="expectedCandidateCTC">
                      Expected Candidate CTC
                    </option>
                    <option value="noticePeriod">Notice Period</option>
                  </Select>
                </FormControl>
              </Box>
              <Box
                display={"flex"}
                gap={4}
                flexDir={["column", "column", "row", "row", "row"]}
              >
                <FormControl mb={4}>
                  <FormLabel>
                    Form Title <span style={{ color: "red" }}>*</span>
                  </FormLabel>

                  <Input
                    value={formData.title}
                    name="title"
                    onChange={handleInputChange}
                    placeholder={"Enter a Title"}
                    isRequired
                  />
                </FormControl>
                <FormControl mb={4}>
                  <FormLabel>
                    Form Description <span style={{ color: "red" }}>*</span>
                  </FormLabel>

                  <Input
                    value={formData.description}
                    name="description"
                    onChange={handleInputChange}
                    placeholder={"Enter a Description"}
                    isRequired
                  />
                </FormControl>
              </Box>
              <Button
                type="button"
                colorScheme="green"
                mt="5"
                onClick={handleAddField}
                mb={5}
              >
                Add Field
              </Button>
            </Box>
            {formFields.length == 0 ? null : (
              <Box
                w={["95%", "95%", "80%", "60%", "60%"]}
                display={"flex"}
                flexDir={"column"}
                justifyContent={"center"}
                justifyItems={"center"}
                mx={"auto"}
                boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
                px={10}
                pb={6}
              >
                <Box my={8} fontSize={"28px"} textAlign={"center"}>
                  Customize Form
                </Box>
                {formFields.map((field, index) => (
                  <FormControl
                    key={index}
                    mb={4}
                    display={"flex"}
                    gap={5}
                    flexDir={["column", "column", "row", "row", "row"]}
                  >
                    <Box width={"100%"}>
                      <FormLabel>
                        {" "}
                        {field.label === "firstname"
                          ? "First Name"
                          : field.label === "lastname"
                            ? "Last Name"
                            : field.label === "pannumber"
                              ? "Pan Number"
                              : field.label === "aadharnumber"
                                ? "Aadhar Number"
                                : field.label === "currentCandidateCTC"
                                  ? "Current Candidate CTC"
                                  : field.label === "expectedCandidateCTC"
                                    ? "Expected Candidate CTC"
                                    : field.label === "noticePeriod"
                                      ? "Notice Period"
                                      : field.label.charAt(0).toUpperCase() +
                                        field.label.slice(1)}
                      </FormLabel>
                      <Input
                        variant="flushed"
                        type={field.type}
                        name={field.name}
                        placeholder={
                          field.label === "firstname"
                            ? "Enter your First Name"
                            : field.label === "lastname"
                              ? "Enter your Last Name"
                              : field.label === "pannumber"
                                ? "Enter your Pan Number"
                                : field.label === "aadharnumber"
                                  ? "Enter your Aadhar Number"
                                  : field.label === "currentCandidateCTC"
                                    ? "Current Candidate CTC"
                                    : field.label === "expectedCandidateCTC"
                                      ? "Expected Candidate CTC"
                                      : field.label === "noticePeriod"
                                        ? "Notice Period"
                                        : `Enter your ${field.label}`
                        }
                      />
                    </Box>
                    <Box display={"flex"} gap={3} justifyContent={"end"}>
                      <Box mt={[2, 2, 10, 10, 10]} fontSize={"12px"}>
                        Required
                      </Box>
                      <Switch
                        mt={[2, 2, 10, 10, 10]}
                        colorScheme="teal"
                        isChecked={field.isRequired}
                        onChange={() => handleSwitchChange(index)}
                      />
                      <Center height="30px" mt={[1, 1, 9, 9, 9]}>
                        <Divider
                          orientation="vertical"
                          borderWidth="1px"
                          borderColor="#000"
                        />
                      </Center>
                      <IconButton
                        color={"red.600"}
                        onClick={() => handleRemoveField(index)}
                        mt={[0, 0, 8, 8, 8]}
                        fontSize={"14px"}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </FormControl>
                ))}
                <Button type="submit" colorScheme="blue" mt="3" width={"100%"}>
                  {id ? "Update Form" : "Create Form"}
                </Button>
              </Box>
            )}
          </Box>
        </form>
      </Box>
    </>
  );
};

export default CreateForm;
