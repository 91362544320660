import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "../../config";
// import { Cookies } from "react-cookie";

// const cookies = new Cookies();

// export const getTokenFromCookies = async () => {
//   return cookies.get('token') || localStorage.getItem('token');

// };

export const jobpostApi = createApi({
  reducerPath: "jobpostApi",
  baseQuery: fetchBaseQuery({
    baseUrl: config.apiUrl,
  }),

  endpoints: (builder) => ({
    deleteJobApi: builder.mutation({
      query: (jobid) => ({
        url: `/api/remove_jobpost/${jobid}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
useDeleteJobApiMutation
} = jobpostApi;
