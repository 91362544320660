import { configureStore } from "@reduxjs/toolkit";
import { sidebarSlice } from "./reducers/sidebar";
import { usersApi } from "./api/users";
import { usersSlice } from "./reducers/userSlice";
import { candidateStatusApi } from "./api/candidateStatus";
import { authApi } from "./api/auth";
import { accountContactApi } from "./api/accountContact";
import { jobpostApi } from "./api/jobPost";

const store = configureStore({
  reducer: {
    sidebar: sidebarSlice.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [usersSlice.name]: usersSlice.reducer,
    [candidateStatusApi.reducerPath]: candidateStatusApi.reducer,
    [accountContactApi.reducerPath]: accountContactApi.reducer,
    [jobpostApi.reducerPath]: jobpostApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(usersApi.middleware,candidateStatusApi.middleware,authApi.middleware,accountContactApi.middleware,jobpostApi.middleware),
});

export default store;
