import React, { useEffect, useState } from "react";
import { Box, Button, Input, Text, Textarea } from "@chakra-ui/react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./org.css";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import config from "../../config";
import * as Yup from "yup";
import { useFormik } from "formik";
import "./org.css";
import { Spinner } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { userActivity } from "Utils";
import Select from "react-select";
import { handleCheckStatusText } from "Utils";
import moment from "moment";
import { useGetCandidateStatusMutation } from "../../redux/api/candidateStatus";

const Submissionform = ({
  saveFile,
  handleSubmitSubmissionClose,
  submitSubmission,
  submission,
  getAllSubmissiondata,
  setSubmission,
  handleCreateSubmissionClose,
  handleSearchCandidateClose,
  handleAppliedJobEmailSend,
  handleAppliedJobClientEmailSend,
  handleActivitySubmit,
  handleCandidateActivitySubmit,
  candidatename,
  jobtitle,
  id,
  setId,
  handleEditShow,
  editSubmission,
  handleEditClose,

  handleResumeMatching,
  ParsedSkills,
  missingSkills,
  matchingSkills,
  router,

  loadingResumeScreening,
  parseResumeConclusion,
  setLoadingResumeScreening,
  parsePercentage,
  parseAge,
  component,
  getCandidateSubmission,
  handleUpdateStatus,
  clearFilter,
  getCandidatedata,
  candidateStatus,
  moduleData,
  applicationStatus,
  handleInputs,
  page,
  rowsPerPage,
}) => {
  const [getCandidateStatusTrigger] = useGetCandidateStatusMutation();
  const [changeStatus, setChangeStatus] = useState("");
  const [submissionLoading, setSubmissionLoading] = useState(false);
  const [cookie] = useCookies(["role", "id"]);
  const candidate_ownerName = localStorage?.getItem("candidate_ownerName");
  const userId = cookie.id;
  const userRole = cookie?.role;
  const [selectedClientContactName, setSelectedClientContactName] =
    useState(null);
  const [contacts, setContacts] = useState([]);
  const [jobData, setJobData] = useState({});

  const findDate = (changeDate) => {
    const selectedDate = new Date(changeDate);
    console.log("selectedDate:::::::::::::", selectedDate);
    if (selectedDate && selectedDate !== "Invalid date") {
      const day = String(selectedDate.getDate()).padStart(2, "0");
      const month = String(selectedDate.getMonth() + 1).padStart(2, "0");
      const year = selectedDate.getFullYear();
      const formattedDate = `${day}/${month}/${year}`;

      return formattedDate;
    }
    return "";
  };
  const filterContact = contacts?.find(
    (res) => String(res._id) === String(submission?.contactPerson_id)
  );

  const candidateNoteAdd = async (note) => {
    try {
      const result = await axios.post(
        `${config?.apiUrl}/api/Set_Candidate_Note`,
        {
          candidateId: note?.candidateId,
          candidateNote: note?.candidateNote,
          noteSubmitter: `Created by ${candidate_ownerName}`,
          created_at: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        }
      );

      toast.success(result.data.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      const candidateFullNmae = result?.data?.fullName;
      const activityOn = `Add candidate note for ${candidateFullNmae}`;
      const activityDo = "Add candidate note";
      const component = "note";

      userActivity(
        candidate_ownerName,
        userId,
        component,
        activityDo,
        activityOn,
        userRole
      );

      handleActivitySubmit(
        result?.data?.data?.candidateId,
        `Note added to ${candidateFullNmae}`,
        new Date().getTime(),
        candidate_ownerName,
        userId
      );
    } catch (error) {
      console.error("Error adding candidate note:", error);
      toast.error("Failed to add candidate note. Please try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const addSubmission = Yup.object().shape({
    currentCandidateCTCType: Yup.string().required("Current CTC Type Required"),
    currentCandidateCTC: Yup.string().required("Current CTC Required"),
    expectedCandidateCTCType: Yup.string().required(
      "Expected CTC Type Required"
    ),
    expectedCandidateCTC: Yup.string().required("Expected CTC Required"),
    noticePeriod: Yup.string().required("Notice Period"),
    relevantExperiance: Yup.string().required("Relevant Expereience "),
    availability: Yup.string().required("When You Avaliable"),
    engagement: Yup.string().required("Engagment Is Required"),
    locationPreference: Yup.string().required("Your Preference Location"),
    // QnA: Yup.string(),
    interviewScheduleDate:
      submission &&
      (submission.status === "Interview Scheduled" ||
        submission.status === "Interview feedback Awaiting" ||
        submission.status === "Interview Complete" ||
        candidateStatus == "Interview Scheduled" ||
        candidateStatus == "Interview feedback Awaiting" ||
        candidateStatus == "Interview Complete")
        ? Yup.string().required("Interview Schedule Date Required")
        : Yup.string(),
    interviewScheduleTime:
      submission &&
      (submission.status === "Interview Scheduled" ||
        submission.status === "Interview feedback Awaiting" ||
        submission.status === "Interview Complete" ||
        candidateStatus == "Interview Scheduled" ||
        candidateStatus == "Interview feedback Awaiting" ||
        candidateStatus == "Interview Complete")
        ? Yup.string().required("Interview Schedule Time Required")
        : Yup.string(),
    interviewScheduleLocation:
      submission &&
      (submission.status === "Interview Scheduled" ||
        submission.status === "Interview feedback Awaiting" ||
        submission.status === "Interview Complete" ||
        candidateStatus == "Interview Scheduled" ||
        candidateStatus == "Interview feedback Awaiting" ||
        candidateStatus == "Interview Complete")
        ? Yup.string().required("Interview Schedule Location Required")
        : Yup.string(),
    interviewScheduleLink: Yup.string(),
    joinDate:
      submission &&
      (submission?.status == "Joined" ||
        candidateStatus == "Joined" ||
        submission?.status == "Offered - Joining Pending" ||
        candidateStatus == "Offered - Joining Pending")
        ? Yup.string().required("Joining Date Required")
        : Yup.string(),
    actualCTC:
      submission &&
      (submission?.status == "Joined" ||
        candidateStatus == "Joined" ||
        submission?.status == "Offered - Joining Pending" ||
        candidateStatus == "Offered - Joining Pending")
        ? Yup.string().required("Actual CTC Required")
        : Yup.string(),
    expectedJoinDate:
      submission &&
      (submission?.status == "Selected" ||
        candidateStatus == "Selected" ||
        submission?.status == "Selected - Document Pending" ||
        candidateStatus == "Selected - Document Pending")
        ? Yup.string().required("Expected Join Date Required")
        : Yup.string(),
    offerCTC:
      submission &&
      (submission?.status == "Selected" ||
        candidateStatus == "Selected" ||
        submission?.status == "Selected - Document Pending" ||
        candidateStatus == "Selected - Document Pending")
        ? Yup.string().required("Offer CTC Required")
        : Yup.string(),
    clientContactName: Yup.string().required(
      "Contact person name is required."
    ),
    message:
      (candidateStatus || submission) &&
      (submission?.status == "Backout" ||
        candidateStatus == "Backout" ||
        submission?.status == "Client - Rejected" ||
        candidateStatus == "Client - Rejected" ||
        submission?.status == "Rejected" ||
        candidateStatus == "Rejected" ||
        submission?.status == "Interview - Rejected" ||
        candidateStatus == "Interview - Rejected" ||
        submission?.status == "Internal - Rejected By TL" ||
        candidateStatus == "Internal - Rejected By TL" ||
        submission?.status == "Not Interested" ||
        candidateStatus == "Not Interested" ||
        submission?.status == "On hold" ||
        candidateStatus == "On hold" ||
        submission?.status == "Test - Not Cleared" ||
        candidateStatus == "Test - Not Cleared" ||
        submission?.status == "Position on Hold" ||
        candidateStatus == "Position on Hold" ||
        submission?.status == "Client - Duplicate" ||
        candidateStatus == "Client - Duplicate" ||
        submission?.status == "First round done" ||
        candidateStatus == "First round done" ||
        submission?.status == "Second round done" ||
        candidateStatus == "Second round done")
        ? Yup.string().required("Message is Required")
        : Yup.string(),
    interviewRescheduleDate:
      submission &&
      (submission?.status == "Interview - Reschedule" ||
        candidateStatus == "Interview - Reschedule")
        ? Yup.string().required("Date is Required")
        : Yup.string(),
    testRescheduleDate:
      submission?.status == "Test - Reschedule" ||
      candidateStatus == "Test - Reschedule"
        ? Yup.string().required("Date is Required")
        : Yup.string(),
    backoutDate:
      submission &&
      (submission?.status == "Backout" || candidateStatus == "Backout")
        ? Yup.string().required("Date is Required")
        : Yup.string(),
  });

  const getContactdata = (id) => {
    axios
      .get(`${config?.apiUrl}/api/get_client_contact/${id}`, {
        headers: {
          Authorization: `Bearer ${cookie.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const contactsData = response.data.data;
        setContacts(contactsData);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  // console.log("submission::::::::", submission);
  // console.log("jobData::::::",jobData)
  const getJobpost = (id) => {
    axios
      .get(`${config?.apiUrl}/jobpostview/${id}`)
      .then((response) => {
        const jobpostdata = response.data.data;
        console.log("jobpostdata============", jobpostdata);

        setJobData(jobpostdata);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  useEffect(() => {
    // debugger
    submission &&
      submission?.clientId &&
      getContactdata(
        submission?.clientId?._id
          ? submission?.clientId?._id
          : submission?.clientId
      );
    submission &&
      submission?.jobId &&
      getJobpost(
        submission?.jobId?._id ? submission?.jobId?._id : submission?.jobId
      );
    setSelectedClientContactName({
      label: filterContact?.accountOwnerName,
      value: filterContact?._id,
    });
  }, [submission]);

  const formik = useFormik({
    initialValues: {
      jobId: "",
      Questions: [],
      clientId: "",
      candidateId: "",
      currentCandidateCTCType: "Annual Salary",
      currentCandidateCTC: "",
      expectedCandidateCTCType: "Annual Salary",
      expectedCandidateCTC: "",
      noticePeriod: "",
      relevantExperiance: "",
      availability: "Available",
      engagement: "Permanent",
      locationPreference: "",
      QnA: [],
      interviewScheduleDate: "",
      interviewScheduleTime: "",
      interviewScheduleLocation: "",
      interviewScheduleLink: "",
      joinDate: "",
      actualCTC: "",
      expectedJoinDate: "",
      offerCTC: "",
      clientContactName: "",
      message: "",
      interviewRescheduleDate: "",
      testRescheduleDate: "",
      backoutDate: "",
    },
    validationSchema: addSubmission,
    onSubmit: async (data) => {
      setSubmissionLoading(true);
      const formData = {
        jobId: submission.jobId,
        Questions: data?.Questions ? data?.Questions : [],
        // Questions: data.Questions ? data.Questions : JSON.stringify(submission.Questions),
        clientId: submission.clientId,
        candidateId: submission.candidateId,
        currentCandidateCTCType: data.currentCandidateCTCType,
        currentCandidateCTC: data.currentCandidateCTC,
        expectedCandidateCTCType: data.expectedCandidateCTCType,
        expectedCandidateCTC: data.expectedCandidateCTC,
        noticePeriod: data.noticePeriod,
        relevantExperiance: data.relevantExperiance,
        availability: data.availability,
        engagement: data.engagement,
        locationPreference: data.locationPreference,
        QnA: data?.QnA ? data?.QnA : [],
        // QnA: JSON.stringify(submission.QnA),
        file: submission.file,
        fileName: submission.fileName,
        filePath: submission.filePath,
        candidate_ownerName: submission?.candidate_ownerName?._id,
        userId: cookie.id,
        // status: submission.status,
        email: submission.email,
        clientemail: submission.clientemail,
        status: data.status
          ? data.status
          : candidateStatus
            ? candidateStatus
            : submission.status || "Internal-Submitted",
        submitterName: submission?.submitterName || data?.clientContactName,
        interviewScheduleDate: data?.interviewScheduleDate || "",
        interviewScheduleTime: data?.interviewScheduleTime || "",
        interviewScheduleLocation: data?.interviewScheduleLocation || "",
        interviewScheduleLink: data?.interviewScheduleLink || "",
        joinDate: data?.joinDate
          ? findDate(data?.joinDate) !== "Invalid date"
            ? findDate(data?.joinDate)
            : "" || ""
          : submission?.joinDate || "",
        submissionBy: submission?.submissionBy
          ? submission?.submissionBy
          : userId,
        updatedBy: userId,
        actualCTC: data?.actualCTC || "",
        expectedJoinDate: data?.expectedJoinDate || "",
        offerCTC: data?.offerCTC || "",
        message: data?.message || "",
        interviewRescheduleDate: data?.interviewRescheduleDate || "",
        testRescheduleDate: data?.testRescheduleDate || "",
        backoutDate: data?.backoutDate || "",
      };


      if (submission?.submitterName == "" && data.clientContactName !== "") {
        const url = `${config?.apiUrl}/api/update_jobpost/${formData.jobId}`;
        try {
          const jobResponse = await axios.put(url, {
            clientContactName: data.clientContactName,
          });
          toast.success("Contact person name is updated", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } catch (error) {
          toast.error(err?.response?.data?.msg || "Please check details", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setSubmissionLoading(false);
        }
      }

      if (id || submission?._id) {
        let configdata = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        const url = `${config?.apiUrl}/api/appliedjob_update/${id || submission?._id}`;
        await axios
          .put(url, formData, configdata)
          .then(async (response) => {
            toast.success(response.data.msg, {
              position: toast.POSITION.TOP_RIGHT,
            });
            const activityOn = `Submission ${
              candidatename?.firstName
                ? candidatename?.firstName + " " + candidatename?.lastName
                : candidatename?.candiname
                  ? candidatename?.candiname
                  : ""
            }  has been  updated `;
            userActivity(
              candidate_ownerName,
              userId,
              component,
              "Update Submission",
              activityOn,
              userRole
            );
            const resData = response?.data?.data;
            const data = {
              candidateId: resData?.candidateId,
              candidateNote: resData?.message,
            };
            if (
              resData &&
              resData?.message?.trim() != submission?.message?.trim()
            ) {
              candidateNoteAdd(data);
            }

            const DefaultFun = handleCheckStatusText(
              candidatename,
              resData,
              submission,
              jobData
            );

            handleCandidateActivitySubmit &&
              handleCandidateActivitySubmit(
                resData.candidateId,
                DefaultFun,
                new Date().getTime(),
                candidate_ownerName
              );

            const candidateId = response?.data?.data?.candidateId;
            const resonoseData = response?.data?.data;
            const updateData = {
              status: resonoseData?.status,
            };
            if (resonoseData?.status) {
              const candidateStatus = await getCandidateStatusTrigger({
                applicationStatus: resonoseData.status,
              }).unwrap();
              updateData.candidateStatus = candidateStatus || "Available";
            }
            const url = `${config?.apiUrl}/api/update_candidate/${candidateId}`;
            await axios
              .put(url, updateData)
              .then((response) => {
                // getCandidatedata && getCandidatedata(0, 10, moduleData);
                getCandidatedata && getCandidatedata("", 0, rowsPerPage);
                const mailSend = response.data.data?.sendMail;
              })
              .catch((err) => {
                console.log("err:::::::update_candidate::", err);
                toast.error(
                  err?.response?.data?.msg ||
                    "Issue on the update candidate submission",
                  {
                    position: toast.POSITION.TOP_RIGHT,
                  }
                );
              });

            // getAllSubmissiondata && getAllSubmissiondata(0, 10, moduleData);
            getAllSubmissiondata && getAllSubmissiondata("", 0, 10);
            handleEditClose && handleEditClose();
            formik.resetForm();
            handleSubmitSubmissionClose && handleSubmitSubmissionClose();
            setId("");
            setSubmissionLoading(false);
          })
          .catch((err) => {
            console.log("err:::::::::appliedjob_update::::::", err);
            toast.error(err?.response?.data?.msg || "Please check details", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setSubmissionLoading(false);
          });
      } else {
        console.log("in else-----------------");
        let configdata = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        try {
          // debugger
          console.log('selectedClientContactName======',selectedClientContactName);
          
            axios
            .post(
              `${config?.apiUrl}/api/candidate_applyjob`,
              {
                ...formData,
                contactPerson_id: selectedClientContactName?.value,
              },
              configdata
            )
            .then(async (result) => {
              toast.success(result.data.msg, {
                position: toast.POSITION.TOP_RIGHT,
              });
              const resonoseData = result.data.data;
              const data = {
                candidateId: resonoseData?.candidateId,
                candidateNote: resonoseData?.message,
              };
              if (resonoseData && resonoseData?.message) {
                candidateNoteAdd(data);
              }

              const activityOn = `${
                candidatename?.firstName
                  ? candidatename?.firstName + " " + candidatename?.lastName
                  : candidatename.candiname
              } has been Submitted to the ${jobtitle && jobtitle?.jbt} job `;
              const activityDo = "Add new Submission";
              userActivity(
                candidate_ownerName,
                userId,
                component,
                activityDo,
                activityOn,
                userRole
              );
              setChangeStatus && setChangeStatus("");
              const candidateId = await resonoseData?.candidateId;
              const updateData = {
                status: resonoseData?.status,
              };
              if (
                resonoseData?.status === "Joined" ||
                resonoseData?.status === "Submitted" ||
                resonoseData?.status === "Client - Submitted" ||
                resonoseData?.status === "Internal-Submitted"
              ) {
                updateData.candidateStatus =
                  resonoseData?.status === "Client - Submitted"
                    ? "QA Check"
                    : resonoseData?.status === "Internal-Submitted"
                      ? "Submitted"
                      : resonoseData?.status;
              }
              const url = `${config?.apiUrl}/api/update_candidate/${candidateId}`;
              await axios
                .put(url, updateData)
                .then((response) => {
                  const mailSend = response.data.data?.sendMail;
                  // getCandidatedata && getCandidatedata(0, 10, moduleData);
                  getCandidatedata && getCandidatedata("", 0, rowsPerPage);
                })
                .catch((err) => {
                  console.log("err:::::::update_candidate::", err);
                  toast.error(
                    err?.response?.data?.msg ||
                      "Issue on the update candidate submission",
                    {
                      position: toast.POSITION.TOP_RIGHT,
                    }
                  );
                });
              getCandidateSubmission && getCandidateSubmission(candidateId);
              // getAllSubmissiondata && getAllSubmissiondata(0, 10, moduleData);
              getAllSubmissiondata && getAllSubmissiondata("", page, rowsPerPage);
              component == "candidate" &&
                (resonoseData.status == "Pending" ||
                  resonoseData.status == "For Future Position") &&
                handleUpdateStatus &&
                handleUpdateStatus();

              setSubmission({
                jobId: "",
                clientId: "",
                candidateId: "",
                Questions: "",
                email: "",
                clientemail: "",
                status: "",
                currentCandidateCTCType: "",
                currentCandidateCTC: "",
                expectedCandidateCTCType: "",
                expectedCandidateCTC: "",
                noticePeriod: "",
                relevantExperiance: "",
                availability: "",
                engagement: "",
                locationPreference: "",
                QnA: [],
              });
              handleCreateSubmissionClose && handleCreateSubmissionClose();
              handleSearchCandidateClose && handleSearchCandidateClose();
              handleSubmitSubmissionClose && handleSubmitSubmissionClose();
              // handleAppliedJobEmailSend && handleAppliedJobEmailSend(formData);
              // handleAppliedJobClientEmailSend &&
              //   handleAppliedJobClientEmailSend(formData);
              handleActivitySubmit &&
                handleActivitySubmit(
                  submission.jobId,
                  `Candidate " ${
                    candidatename?.firstName
                      ? candidatename?.firstName + " " + candidatename?.lastName
                      : candidatename.candiname
                  } "has been submitted to " ${jobtitle.jbt} job by ${candidate_ownerName}"`,
                  new Date().getTime(),
                  candidate_ownerName,
                  userId
                );

              handleCandidateActivitySubmit &&
                handleCandidateActivitySubmit(
                  submission.candidateId,
                  `Candidate " ${
                    candidatename?.firstName
                      ? candidatename?.firstName + " " + candidatename?.lastName
                      : candidatename.candiname
                  } " has submitted to ${jobtitle.jbt} for ${jobData?.clientId?.accountName}`,
                  new Date().getTime(),
                  candidate_ownerName
                );
              setId && setId("");
              setSubmissionLoading(false);
            })
            .catch(async (err) => {
              console.log("err:::::::candidate_applyjob::", err);
              toast.error(
                err?.response?.data?.msg ||
                  "Candidate Already Submitted this Job",
                {
                  position: toast.POSITION.TOP_RIGHT,
                }
              );
              handleSubmitSubmissionClose && handleSubmitSubmissionClose();
              setSubmissionLoading(false);
            });
        } catch (error) {
          console.log("Getting error from get contact api", error);
        }
        
      }
    },
  });
  // set value in formik on edit
  useEffect(() => {
    if (id || submission?._id) {
      // debugger;
      // console.log("submission------", submission);
      // console.log("contact ------", contacts);

      setSelectedClientContactName({
        label: filterContact?.accountOwnerName,
        value: filterContact?._id,
      });
      formik.setValues({
        jobId: submission.jobId,
        Questions: submission.Questions,
        clientId: submission.clientId,
        candidateId: submission.candidateId,
        currentCandidateCTCType: submission.currentCandidateCTCType,
        currentCandidateCTC: submission.currentCandidateCTC,
        expectedCandidateCTCType: submission.expectedCandidateCTCType,
        expectedCandidateCTC: submission.expectedCandidateCTC,
        noticePeriod: submission.noticePeriod,
        relevantExperiance: submission.relevantExperiance,
        availability: submission.availability,
        engagement: submission.engagement,
        locationPreference: submission.locationPreference,
        QnA: submission.QnA,
        file: submission.file,
        fileName: submission.fileName,
        filePath: submission.filePath,
        candidate_ownerName: localStorage?.getItem("candidate_ownerName"),
        userId: submission?.userId,
        status: candidateStatus ? candidateStatus : submission.status,
        email: submission.email,
        clientemail: submission.clientemail,
        interviewScheduleDate: submission?.interviewScheduleDate,
        interviewScheduleTime: submission?.interviewScheduleTime,
        interviewScheduleLocation: submission?.interviewScheduleLocation,
        interviewScheduleLink: submission?.interviewScheduleLink,
        // joinDate: submission?.joinDate
        //   ? moment(submission?.joinDate,"DD/MM/YYYY").format("YYYY-MM-DD")
        //   : "",
        clientContactName: filterContact?._id,
        actualCTC: submission?.actualCTC,
        expectedJoinDate: submission?.expectedJoinDate,
        offerCTC: submission?.offerCTC,
        message: submission?.message || "",
        testRescheduleDate: submission?.testRescheduleDate,
        interviewRescheduleDate: submission?.interviewRescheduleDate,
        backoutDate: submission?.backoutDate,
      });

      if (submission?.joinDate) {
        const joinDate = submission.joinDate;

        // Check if the date is in ISO format or DD/MM/YYYY
        const formattedDate = moment(joinDate, moment.ISO_8601, true).isValid()
          ? moment(joinDate).format("YYYY-MM-DD") // ISO format
          : moment(joinDate, "DD/MM/YYYY").format("YYYY-MM-DD"); // DD/MM/YYYY format

        formik.setFieldValue("joinDate", formattedDate);
      }
    } else {
      formik.resetForm();
    }
  }, [submission, id, contacts]);

  // set value in formik on Add ,if that value is available in the candidate.
  useEffect(() => {
    const callData = async () => {
      try {
        if (
          (submission && submission?.clientId) ||
          submission?.relevantExperiance
        ) {
          if (!submission?._id) {
            // debugger
            const { data } = await axios.get(
              `${config?.apiUrl}/api/get_contact/${submission?.jobId}`
            );
           if(data?.success && formik){
            formik.setValues({
              currentCandidateCTCType: submission?.currentCandidateCTCType
                ? submission?.currentCandidateCTCType
                : "Annual Salary",
              currentCandidateCTC: submission?.currentCandidateCTC
                ? submission?.currentCandidateCTC
                : "",
              expectedCandidateCTCType: submission?.expectedCandidateCTCType
                ? submission?.expectedCandidateCTCType
                : "Annual Salary",
              expectedCandidateCTC: submission?.expectedCandidateCTC
                ? submission?.expectedCandidateCTC
                : "",
              noticePeriod: submission?.noticePeriod
                ? submission?.noticePeriod
                : "",
              relevantExperiance: submission.relevantExperiance
                ? submission.relevantExperiance
                : "",
              availability: submission?.availability
                ? submission?.availability
                : "Available",
              engagement: submission?.engagement
                ? submission?.engagement
                : "Permanent",
              locationPreference: submission?.locationPreference
                ? submission?.locationPreference
                : "",
              // clientContactName: data?.contactPerson?.clientContactName_Id?._id,
            });
            formik.setFieldValue("clientContactName", data?.contactPerson?.clientContactName_Id?._id);
            setSelectedClientContactName({
              value: data?.contactPerson?.clientContactName_Id?._id,
              label: data?.contactPerson?.clientContactName_Id?.accountOwnerName,
            });
           }
          }
        }
      } catch (error) {
        console.log(error);
        
      }
    };
    callData()
  }, [ submission,contacts]);

  

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const tomorrowDate = tomorrow.toISOString().split("T")[0];
    const oneMonth = new Date();
    oneMonth.setDate(oneMonth.getDate() + 30);
    const oneMonthDate = oneMonth.toISOString().split("T")[0];
    // if (
    //   (candidateStatus && candidateStatus == "Joined") ||
    //   (submission && submission?.status == "Joined")
    // ) {
    //   debugger
    //   formik.setFieldValue("joinDate", today);
    // }
    if (
      (candidateStatus && candidateStatus == "Selected") ||
      (submission && submission?.status == "Selected")
    ) {
      formik.setFieldValue("expectedJoinDate", today);
    }
    if (
      (candidateStatus && candidateStatus == "Interview - Reschedule") ||
      (submission && submission?.status == "Interview - Reschedule")
    ) {
      formik.setFieldValue("interviewRescheduleDate", tomorrowDate);
    }
    if (
      (candidateStatus && candidateStatus == "Test - Reschedule") ||
      (submission && submission?.status == "Test - Reschedule")
    ) {
      formik.setFieldValue("testRescheduleDate", oneMonthDate);
    }
    if (
      (candidateStatus && candidateStatus == "Backout") ||
      (submission && submission?.status == "Backout")
    ) {
      formik.setFieldValue("backoutDate", today);
    }
  }, [submission, candidateStatus]);

  return (
    <>
      <Modal
        show={submitSubmission}
        onHide={() => {
          handleSubmitSubmissionClose();
          formik.resetForm();
          clearFilter && clearFilter();
          setSubmission({});
          setId && setId("");
          setChangeStatus && setChangeStatus("");
        }}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="usertitle">
            {id || submission?._id ? "Update Submission" : "Save Submission"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {jobData && (
            <Box sx={{ padding: "0rem 0.3rem 1rem", display: "flex", gap: 5 }}>
              <Text sx={{ fontWeight: 700 }}>
                Client Name :{" "}
                <span className="usertitle">
                  {jobData?.clientId?.accountName},
                </span>
              </Text>
              <Text sx={{ fontWeight: 700 }}>
                Job Title :{" "}
                <span className="usertitle">{jobData?.jobtitle}</span>
              </Text>
              <Text sx={{ fontWeight: 700 }}>
                Job Owner :{" "}
                <span className="usertitle">{`${jobData?.job_owener_data?.firstName} ${jobData?.job_owener_data?.lastName}`}</span>
              </Text>
              {candidateStatus && (
                <text>
                  Submission status :{" "}
                  <span className="usertitle">{submission?.status}</span>
                </text>
              )}

              {candidateStatus && (
                <div>
                  {" "}
                  <label
                    style={{
                      fontSize: "15px",
                      fontWeight: "400",
                      fontFamily: "Roboto",
                      color: "#000000",
                    }}
                  >
                    Current Status
                  </label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    name="status"
                    value={
                      changeStatus
                        ? changeStatus
                        : candidateStatus
                          ? candidateStatus
                          : submission.status
                    }
                    onChange={(item) => {
                      console.log("item::::", item.target.value);
                      setChangeStatus(item.target.value);
                      handleInputs && handleInputs(item);
                      formik?.setFieldValue("status", item?.target.value);
                    }}
                  >
                    <option disabled={true} value="">
                      Application Status
                    </option>
                    {applicationStatus?.map((val) => (
                      <>
                        <option value={val?.CandidateApplicationStatus}>
                          {val?.CandidateApplicationStatus}
                        </option>
                      </>
                    ))}
                  </select>
                </div>
              )}
            </Box>
          )}
          <h1
            style={{
              fontWeight: "bold",
              fontSize: "1.3rem",
              padding: "0.3rem",
              paddingBottom: "15px",
            }}
            className="usertitle"
          >
            Submission Details
          </h1>
          <Container>
            <form onSubmit={formik.handleSubmit}>
              <Row>
                <Col lg={6} md={6} style={{ marginBottom: "2rem" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Current Candidate CTC"
                      name="currentCandidateCTC"
                      value={formik.values.currentCandidateCTC}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      style={{
                        height: "37px",
                        border:
                          formik.touched.currentCandidateCTC &&
                          formik.errors.currentCandidateCTC
                            ? "1px solid red"
                            : "",
                      }}
                    />

                    <select
                      className="form-control"
                      name="currentCandidateCTCType"
                      value={formik.values.currentCandidateCTCType}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      style={{
                        height: "37px",
                        fontSize: "14px",
                        width: "auto",
                        border:
                          formik.touched.currentCandidateCTCType &&
                          formik.errors.currentCandidateCTCType
                            ? "1px solid red"
                            : "",
                      }}
                    >
                      <option disabled={true} value="">
                        Select
                      </option>
                      <option>Monthly</option>
                      <option>Annual Salary</option>
                      <option>Other</option>
                    </select>
                  </div>
                </Col>
                <Col lg={6} md={6} style={{ marginBottom: "2rem" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Expected Candidate CTC"
                      name="expectedCandidateCTC"
                      value={formik.values.expectedCandidateCTC}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      style={{
                        height: "37px",
                        border:
                          formik.touched.expectedCandidateCTC &&
                          formik.errors.expectedCandidateCTC
                            ? "1px solid red"
                            : "",
                      }}
                    />
                    <select
                      className="form-control"
                      name="expectedCandidateCTCType"
                      value={formik.values.expectedCandidateCTCType}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      style={{
                        height: "37px",
                        fontSize: "14px",
                        width: "auto",
                        border:
                          formik.touched.expectedCandidateCTCType &&
                          formik.errors.expectedCandidateCTCType
                            ? "1px solid red"
                            : "",
                      }}
                    >
                      <option disabled={true} value="">
                        Select
                      </option>
                      <option>Monthly</option>
                      <option>Annual Salary</option>
                      <option>Other</option>
                    </select>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={6} style={{ marginBottom: "24px" }}>
                  <label
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      fontFamily: "Roboto",
                      color: "#000000",
                    }}
                  >
                    Notice Period
                  </label>
                  <Input
                    variant="auth"
                    fontSize="sm"
                    type="text"
                    placeholder="Notice Period"
                    size="lg"
                    name="noticePeriod"
                    value={formik.values.noticePeriod}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    style={{
                      height: "37px",
                      border:
                        formik.touched.noticePeriod &&
                        formik.errors.noticePeriod
                          ? "1px solid red"
                          : "",
                    }}
                  />
                  {formik.touched.noticePeriod &&
                    formik.errors.noticePeriod && (
                      <div style={{ color: "red", fontSize: "14px" }}>
                        {" "}
                        {formik.errors.noticePeriod}{" "}
                      </div>
                    )}
                </Col>
                <Col lg={6} md={6} style={{ marginBottom: "24px" }}>
                  <label
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      fontFamily: "Roboto",
                      color: "#000000",
                    }}
                  >
                    Relevant Experience
                  </label>
                  <Input
                    variant="auth"
                    fontSize="sm"
                    type="text"
                    placeholder="Relevant Experience"
                    size="lg"
                    name="relevantExperiance"
                    value={formik.values.relevantExperiance}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    style={{
                      height: "37px",
                      border:
                        formik.touched.relevantExperiance &&
                        formik.errors.relevantExperiance
                          ? "1px solid red"
                          : "",
                    }}
                  />
                  {formik.touched.relevantExperiance &&
                    formik.errors.relevantExperiance && (
                      <div style={{ color: "red", fontSize: "14px" }}>
                        {" "}
                        {formik.errors.relevantExperiance}{" "}
                      </div>
                    )}
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={6} style={{ marginBottom: "24px" }}>
                  <label
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      fontFamily: "Roboto",
                      color: "#000000",
                    }}
                  >
                    Availability
                  </label>
                  <Input
                    variant="auth"
                    fontSize="sm"
                    type="text"
                    placeholder="Availability"
                    size="lg"
                    name="availability"
                    value={formik.values.availability}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    style={{
                      height: "37px",
                      border:
                        formik.touched.availability &&
                        formik.errors.availability
                          ? "1px solid red"
                          : "",
                    }}
                  />
                  {formik.touched.availability &&
                    formik.errors.availability && (
                      <div style={{ color: "red", fontSize: "14px" }}>
                        {" "}
                        {formik.errors.availability}{" "}
                      </div>
                    )}
                </Col>
                <Col lg={6} md={6} style={{ marginBottom: "2rem" }}>
                  <label
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      fontFamily: "Roboto",
                      color: "#000000",
                    }}
                  >
                    Engagement
                  </label>
                  <select
                    className="form-control"
                    name="engagement"
                    value={formik.values.engagement}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    style={{
                      height: "37px",
                      border:
                        formik.touched.engagement && formik.errors.engagement
                          ? "1px solid red"
                          : "",
                    }}
                  >
                    <option disabled={true} value="">
                      Select
                    </option>
                    <option>Permanent</option>
                    <option>Contract</option>
                    <option>C2H</option>
                  </select>
                  {formik.touched.engagement && formik.errors.engagement && (
                    <div style={{ color: "red", fontSize: "14px" }}>
                      {" "}
                      {formik.errors.engagement}{" "}
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={6} style={{ marginBottom: "2rem" }}>
                  <label
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      fontFamily: "Roboto",
                      color: "#000000",
                    }}
                  >
                    Resume
                  </label>
                  <div class="file-dummy">
                    <div class="default">
                      <span class="drag">Drag and Drop Resume</span>
                      <br />
                      or you can <span class="browse">Browse</span> and find a
                      file here
                    </div>
                    <input
                      type="file"
                      id="submission-file-input"
                      onChange={saveFile}
                      accept=".pdf,.jpg,.jpeg,.png,.doc,.docx"
                    />
                  </div>
                  <div>
                    <div
                      className="btn bg-white border rounded mb-2 text-break"
                      style={{ width: "100%" }}
                    >
                      {!submission?.fileName ? "N/A" : submission?.fileName}
                    </div>
                  </div>
                </Col>
                <Col lg={6} md={6} style={{ marginBottom: "24px" }}>
                  <Box style={{ marginBottom: "24px" }}>
                    <label
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        fontFamily: "Roboto",
                        color: "#000000",
                      }}
                    >
                      Location Preference
                    </label>
                    <Input
                      variant="auth"
                      fontSize="sm"
                      type="text"
                      placeholder="Location Preference"
                      size="lg"
                      name="locationPreference"
                      value={formik.values.locationPreference}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      style={{
                        height: "37px",
                        border:
                          formik.touched.locationPreference &&
                          formik.errors.locationPreference
                            ? "1px solid red"
                            : "",
                      }}
                    />
                    {formik.touched.locationPreference &&
                      formik.errors.locationPreference && (
                        <div style={{ color: "red", fontSize: "14px" }}>
                          {" "}
                          {formik.errors.locationPreference}{" "}
                        </div>
                      )}
                  </Box>
                  <Box style={{ marginBottom: "24px" }}>
                    <label
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        fontFamily: "Roboto",
                        color: "#000000",
                      }}
                    >
                      Contact Person Name
                    </label>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 5,
                        justifyContent: "space-between",
                      }}
                    >
                      <Select
                        id="clientContactName"
                        name="clientContactName"
                        classNamePrefix="Select Contact Person Name"
                        placeholder="Select Contact Person Name"
                        value={selectedClientContactName}
                        onChange={(selectedOption) => {
                          setSelectedClientContactName(selectedOption);

                          formik.setFieldValue(
                            "clientContactName",
                            selectedOption?.value
                          );
                        }}
                        isDisabled={
                          submission?.contactPerson_id 
                          &&
                          contacts.filter((el) => {
                          return el._id == selectedClientContactName?.value;
                          }).length > 0
                        }
                        onBlur={formik.handleBlur}
                        options={
                          contacts &&
                          contacts.length !== 0 &&
                          contacts?.map((val) => ({
                            value: val._id,
                            label: val.accountOwnerName,
                          }))
                        }
                        className="selectDropDown"
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor:
                              formik.touched.clientContactName &&
                              formik.errors.clientContactName
                                ? "red"
                                : "#ccc",
                          }),
                        }}
                      />
                      {/* {contacts.length == 0 && (
                        <Button
                          w={"auto"}
                          className="bg_color"
                          color={"#fff"}
                          // type="submit"
                          disabled={parsePercentage && parsePercentage < 50}
                        >
                          +
                        </Button>
                      )} */}
                    </Box>
                    {formik?.touched?.clientContactName &&
                    formik?.errors?.clientContactName ? (
                      <div style={{ color: "red", fontSize: "14px" }}>
                        {formik?.errors?.clientContactName}
                      </div>
                    ) : null}
                  </Box>
                  {/* {!id && ( */}
                  <Box
                    style={{
                      display: (() => {
                        switch (router) {
                          case "candidateview":
                          case "jobpostview":
                          case "candidate":
                          case "pendingcandidate":
                            return "block";
                          default:
                            return "none";
                        }
                      })(),
                    }}
                  >
                    <Button
                      className="bg_color"
                      color={"#fff"}
                      isLoading={loadingResumeScreening}
                      onClick={(e) => {
                        handleResumeMatching(e);
                      }}
                    >
                      Resume Screening
                    </Button>
                  </Box>
                  {/* )} */}
                </Col>
              </Row>
              {submission &&
                (submission.status === "Interview Scheduled" ||
                  submission.status === "Interview feedback Awaiting" ||
                  submission.status === "Interview Complete" ||
                  candidateStatus == "Interview Scheduled" ||
                  candidateStatus == "Interview feedback Awaiting" ||
                  candidateStatus == "Interview Complete") && (
                  <>
                    <Row>
                      <Col lg={6} md={6} style={{ marginBottom: "24px" }}>
                        <label
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            fontFamily: "Roboto",
                            color: "#000000",
                          }}
                        >
                          Interview Schedule Date
                        </label>
                        <Input
                          variant="auth"
                          fontSize="sm"
                          type="date"
                          placeholder="Availability"
                          size="lg"
                          name="interviewScheduleDate"
                          value={formik.values.interviewScheduleDate}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          style={{
                            height: "37px",
                            border:
                              formik.touched.interviewScheduleDate &&
                              formik.errors.interviewScheduleDate
                                ? "1px solid red"
                                : "",
                          }}
                        />
                        {formik?.touched?.interviewScheduleDate &&
                        formik?.errors?.interviewScheduleDate ? (
                          <div style={{ color: "red", fontSize: "14px" }}>
                            {formik?.errors?.interviewScheduleDate}
                          </div>
                        ) : null}
                      </Col>
                      <Col lg={6} md={6} style={{ marginBottom: "24px" }}>
                        <label
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            fontFamily: "Roboto",
                            color: "#000000",
                          }}
                        >
                          Interview Schedule Time
                        </label>
                        <Input
                          variant="auth"
                          fontSize="sm"
                          type="time"
                          placeholder="Availability"
                          size="lg"
                          name="interviewScheduleTime"
                          value={formik.values.interviewScheduleTime}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          style={{
                            height: "37px",
                            border:
                              formik.touched.interviewScheduleTime &&
                              formik.errors.interviewScheduleTime
                                ? "1px solid red"
                                : "",
                          }}
                        />
                        {formik?.touched?.interviewScheduleTime &&
                        formik?.errors?.interviewScheduleTime ? (
                          <div style={{ color: "red", fontSize: "14px" }}>
                            {formik?.errors?.interviewScheduleTime}
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} md={6} style={{ marginBottom: "24px" }}>
                        <label
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            fontFamily: "Roboto",
                            color: "#000000",
                          }}
                        >
                          Interview Schedule Location
                        </label>
                        <Input
                          variant="auth"
                          fontSize="sm"
                          type="text"
                          placeholder="Availability"
                          size="lg"
                          name="interviewScheduleLocation"
                          value={formik.values.interviewScheduleLocation}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          style={{
                            height: "37px",
                            border:
                              formik.touched.interviewScheduleLocation &&
                              formik.errors.interviewScheduleLocation
                                ? "1px solid red"
                                : "",
                          }}
                        />
                        {formik?.touched?.interviewScheduleLocation &&
                        formik?.errors?.interviewScheduleLocation ? (
                          <div style={{ color: "red", fontSize: "14px" }}>
                            {formik?.errors?.interviewScheduleLocation}
                          </div>
                        ) : null}
                      </Col>
                      <Col lg={6} md={6} style={{ marginBottom: "24px" }}>
                        <label
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            fontFamily: "Roboto",
                            color: "#000000",
                          }}
                        >
                          Interview Schedule Link
                        </label>
                        <Input
                          variant="auth"
                          fontSize="sm"
                          type="text"
                          placeholder="Availability"
                          size="lg"
                          name="interviewScheduleLink"
                          value={formik.values.interviewScheduleLink}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          style={{
                            height: "37px",
                          }}
                        />
                      </Col>
                    </Row>
                  </>
                )}

              {submission &&
                (submission?.status == "Joined" ||
                  candidateStatus == "Joined" ||
                  submission?.status == "Offered - Joining Pending" ||
                  candidateStatus == "Offered - Joining Pending") && (
                  <>
                    <Row>
                      <Col lg={6} md={6} style={{ marginBottom: "24px" }}>
                        <label
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            fontFamily: "Roboto",
                            color: "#000000",
                          }}
                        >
                          Joining Date
                        </label>
                        <Input
                          variant="auth"
                          fontSize="sm"
                          type="date"
                          placeholder="Joining date"
                          size="lg"
                          name="joinDate"
                          value={formik.values.joinDate}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          style={{
                            height: "37px",
                            border:
                              formik.touched.joinDate && formik.errors.joinDate
                                ? "1px solid red"
                                : "",
                          }}
                        />
                        {formik?.touched?.joinDate &&
                        formik?.errors?.joinDate ? (
                          <div style={{ color: "red", fontSize: "14px" }}>
                            {formik?.errors?.joinDate}
                          </div>
                        ) : null}
                      </Col>
                      <Col lg={6} md={6} style={{ marginBottom: "24px" }}>
                        <label
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            fontFamily: "Roboto",
                            color: "#000000",
                          }}
                        >
                          Actual CTC
                        </label>
                        <Input
                          variant="auth"
                          fontSize="sm"
                          type="text"
                          placeholder="Actual CTC"
                          size="lg"
                          name="actualCTC"
                          value={formik.values.actualCTC}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          style={{
                            height: "37px",
                            border:
                              formik.touched.actualCTC &&
                              formik.errors.actualCTC
                                ? "1px solid red"
                                : "",
                          }}
                        />
                        {formik?.touched?.actualCTC &&
                        formik?.errors?.actualCTC ? (
                          <div style={{ color: "red", fontSize: "14px" }}>
                            {formik?.errors?.actualCTC}
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                  </>
                )}
              {(candidateStatus || submission) &&
                (candidateStatus == "Interview - Reschedule" ||
                  submission?.status == "Interview - Reschedule") && (
                  <>
                    <Row>
                      <Col lg={6} md={6} style={{ marginBottom: "24px" }}>
                        <label
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            fontFamily: "Roboto",
                            color: "#000000",
                          }}
                        >
                          Interview Reschedule Date
                        </label>
                        <Input
                          variant="auth"
                          fontSize="sm"
                          type="date"
                          placeholder="Interview scheduled date"
                          size="lg"
                          name="interviewRescheduleDate"
                          value={formik.values.interviewRescheduleDate}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          style={{
                            height: "37px",
                            border:
                              formik.touched.interviewRescheduleDate &&
                              formik.errors.interviewRescheduleDate
                                ? "1px solid red"
                                : "",
                          }}
                        />
                        {formik?.touched?.interviewRescheduleDate &&
                        formik?.errors?.interviewRescheduleDate ? (
                          <div style={{ color: "red", fontSize: "14px" }}>
                            {formik?.errors?.interviewRescheduleDate}
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                  </>
                )}
              {submission &&
                (submission?.status == "Selected" ||
                  candidateStatus == "Selected" ||
                  submission?.status == "Selected - Document Pending" ||
                  candidateStatus == "Selected - Document Pending") && (
                  <>
                    <Row>
                      <Col lg={6} md={6} style={{ marginBottom: "24px" }}>
                        <label
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            fontFamily: "Roboto",
                            color: "#000000",
                          }}
                        >
                          Expected Joining Date
                        </label>
                        <Input
                          fontSize="sm"
                          type="date"
                          placeholder="Joining date"
                          size="lg"
                          name="expectedJoinDate"
                          value={formik.values.expectedJoinDate}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          style={{
                            height: "37px",
                            border:
                              formik.touched.expectedJoinDate &&
                              formik.errors.expectedJoinDate
                                ? "1px solid red"
                                : "",
                          }}
                        />

                        {formik.touched.expectedJoinDate &&
                        formik.errors.expectedJoinDate ? (
                          <div style={{ color: "red", fontSize: "14px" }}>
                            {formik.errors.expectedJoinDate}
                          </div>
                        ) : null}
                      </Col>
                      <Col lg={6} md={6} style={{ marginBottom: "24px" }}>
                        <label
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            fontFamily: "Roboto",
                            color: "#000000",
                          }}
                        >
                          Offer CTC
                        </label>
                        <Input
                          fontSize="sm"
                          type="text"
                          placeholder=" Offer CTC"
                          size="lg"
                          name="offerCTC"
                          value={formik.values.offerCTC}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          style={{
                            height: "37px",
                            border:
                              formik.touched.offerCTC && formik.errors.offerCTC
                                ? "1px solid red"
                                : "",
                          }}
                        />
                        {formik?.touched?.offerCTC &&
                        formik?.errors?.offerCTC ? (
                          <div style={{ color: "red", fontSize: "14px" }}>
                            {formik?.errors?.offerCTC}
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                  </>
                )}
              {submission &&
                (submission?.status == "Test - Reschedule" ||
                  candidateStatus == "Test - Reschedule") && (
                  <>
                    <Row>
                      <Col lg={6} md={6} style={{ marginBottom: "24px" }}>
                        <label
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            fontFamily: "Roboto",
                            color: "#000000",
                          }}
                        >
                          Test Reschedule Date
                        </label>
                        <Input
                          fontSize="sm"
                          type="date"
                          placeholder="Test Reschedule Date"
                          size="lg"
                          name="testRescheduleDate"
                          value={formik.values.testRescheduleDate}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          style={{
                            height: "37px",
                            border:
                              formik.touched.testRescheduleDate &&
                              formik.errors.testRescheduleDate
                                ? "1px solid red"
                                : "",
                          }}
                        />

                        {formik.touched.testRescheduleDate &&
                        formik.errors.testRescheduleDate ? (
                          <div style={{ color: "red", fontSize: "14px" }}>
                            {formik.errors.testRescheduleDate}
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                  </>
                )}
              {(candidateStatus || submission) &&
                (submission?.status == "Backout" ||
                  candidateStatus == "Backout" ||
                  submission?.status == "Client - Rejected" ||
                  candidateStatus == "Client - Rejected" ||
                  submission?.status == "Rejected" ||
                  candidateStatus == "Rejected" ||
                  submission?.status == "Interview - Rejected" ||
                  candidateStatus == "Interview - Rejected" ||
                  submission?.status == "Internal - Rejected By TL" ||
                  candidateStatus == "Internal - Rejected By TL" ||
                  submission?.status == "Not Interested" ||
                  candidateStatus == "Not Interested" ||
                  submission?.status == "On hold" ||
                  candidateStatus == "On hold" ||
                  submission?.status == "Test - Not Cleared" ||
                  candidateStatus == "Test - Not Cleared" ||
                  submission?.status == "Position on Hold" ||
                  candidateStatus == "Position on Hold" ||
                  submission?.status == "Client - Duplicate" ||
                  candidateStatus == "Client - Duplicate" ||
                  submission?.status == "First round done" ||
                  candidateStatus == "First round done" ||
                  submission?.status == "Second round done" ||
                  candidateStatus == "Second round done") && (
                  <>
                    <Row>
                      <Col lg={12} md={12} style={{ marginBottom: "24px" }}>
                        <label
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            fontFamily: "Roboto",
                            color: "#000000",
                          }}
                        >
                          Message
                        </label>
                        <Textarea
                          fontSize="sm"
                          type="text"
                          placeholder="Message"
                          size="lg"
                          name="message"
                          resize={"none"}
                          value={formik.values.message}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          style={{
                            height: "37px",
                            border:
                              formik.touched.message && formik.errors.message
                                ? "1px solid red"
                                : "",
                          }}
                        />
                        {formik.touched.message && formik.errors.message ? (
                          <div style={{ color: "red", fontSize: "14px" }}>
                            {formik.errors.message}
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                  </>
                )}
              {submission &&
                (submission?.status == "Backout" ||
                  candidateStatus == "Backout") && (
                  <>
                    <Row>
                      <Col lg={6} md={6} style={{ marginBottom: "24px" }}>
                        <label
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            fontFamily: "Roboto",
                            color: "#000000",
                          }}
                        >
                          Backout Date
                        </label>
                        <Input
                          fontSize="sm"
                          type="date"
                          placeholder="Test Reschedule Date"
                          size="lg"
                          name="backoutDate"
                          value={formik.values.backoutDate}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          style={{
                            height: "37px",
                            border:
                              formik.touched.backoutDate &&
                              formik.errors.backoutDate
                                ? "1px solid red"
                                : "",
                          }}
                        />

                        {formik.touched.backoutDate &&
                        formik.errors.backoutDate ? (
                          <div style={{ color: "red", fontSize: "14px" }}>
                            {formik.errors.backoutDate}
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                  </>
                )}
              <Box
                style={{
                  display: (() => {
                    switch (router) {
                      case "candidateview":
                      case "jobpostview":
                      case "candidate":
                      case "pendingcandidate":
                        return "block";
                      default:
                        return "none";
                    }
                  })(),
                }}
              >
                <Container
                  style={{ margin: "1rem 0", display: "none" }}
                  className="matched_skill"
                >
                  <Row>
                    <Col lg={6} md={6}>
                      <Text style={{ fontWeight: "bold" }}>
                        Required Skills
                      </Text>
                      <Text style={{ color: "gray", marginBottom: "1rem" }}>
                        {submission?.skill}
                      </Text>
                    </Col>
                    <Col lg={6} md={6}>
                      <Text style={{ fontWeight: "bold" }}>Parsed Skills</Text>
                      <Text style={{ color: "gray" }}>
                        {ParsedSkills && ParsedSkills.length > 0
                          ? ParsedSkills.map((val, index) => (
                              <Text key={index}>{val}</Text>
                            ))
                          : "N/A"}
                      </Text>
                    </Col>
                  </Row>

                  <Row
                    style={{
                      border: "1px solid #dbdbdb",
                      padding: "10px",
                      borderRadius: "5px",
                      marginTop: "6px",
                    }}
                  >
                    <Col style={{ borderRight: "1px solid #dbdbdb" }}>
                      <Text style={{ fontWeight: "bold" }}>
                        Matching Skills
                      </Text>
                      <hr />
                      <Text style={{ color: "green", marginTop: "6px" }}>
                        {matchingSkills && matchingSkills?.length == 0
                          ? "No Matching Skills"
                          : matchingSkills?.map((val) => <Text>{val}</Text>)}
                      </Text>
                    </Col>
                    <Col>
                      <Text style={{ fontWeight: "bold" }}>Missing Skills</Text>
                      <hr />
                      <Text style={{ color: "red", marginTop: "6px" }}>
                        {missingSkills && missingSkills?.length == 0
                          ? "No Missing Skills"
                          : missingSkills?.map((val) => <Text>{val}</Text>)}
                      </Text>
                    </Col>
                  </Row>
                </Container>
                <Row
                  style={{ marginBottom: "1rem", display: "none" }}
                  className="conclusion"
                >
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.3rem",
                      padding: "0.3rem",
                      fontFamily: "Roboto",
                    }}
                    className="usertitle"
                  >
                    Compliance Justification:
                  </Text>
                  <Text style={{ padding: "0.3rem" }}>
                    {parseResumeConclusion}
                  </Text>
                  {parsePercentage !== undefined && (
                    <Text>
                      <strong>Matching skill percentage is :</strong>{" "}
                      {parsePercentage} %
                    </Text>
                  )}
                  {parseAge && (
                    <Text>
                      <strong>Candidate age is :</strong> {parseAge}
                    </Text>
                  )}
                </Row>
              </Box>

              <Modal.Footer
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  className="cancel"
                  type="button"
                  onClick={() => {
                    handleSubmitSubmissionClose();
                    formik.resetForm();
                    setId && setId("");
                    clearFilter && clearFilter();
                    setSubmission({});
                    setChangeStatus && setChangeStatus("");
                  }}
                >
                  Cancel
                </Button>
                <Button
                  w={"100px"}
                  className="bg_color"
                  color={"#fff"}
                  type="submit"
                  disabled={parsePercentage && parsePercentage < 50}
                >
                  {submissionLoading ? (
                    <Spinner />
                  ) : id || submission?._id ? (
                    "Update"
                  ) : (
                    "Save"
                  )}
                </Button>
              </Modal.Footer>
            </form>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Submissionform;
