import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "../../config";
import { Cookies } from "react-cookie";

const cookies = new Cookies();

export const getTokenFromCookies = async () => {
  return cookies.get('token') || localStorage.getItem('token'); 

  
};

export const usersApi = createApi({
  reducerPath: "usersApi",
  baseQuery: fetchBaseQuery({
    baseUrl: config.apiUrl,
    prepareHeaders: async (headers) => {
      const token = await getTokenFromCookies(); 
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getUsersNames: builder.query({
      query: () => `/api/users/names`,
    }),
    getUserPermission : builder.query({
      query: (role) => `/api/getpermissions/${role}`,
    }),
    getUserTeamData : builder.query({
      query: (userId) => `/api/get_User_TeamData/${userId}`,
    })

  }),
});

export const { useGetUsersNamesQuery,useLazyGetUsersNamesQuery,useLazyGetUserPermissionQuery,useLazyGetUserTeamDataQuery } = usersApi;
