import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import FilterListIcon from "@mui/icons-material/FilterList";
import Searchicon from "assets/svg/search-icon.svg";
import React from "react";

const PopoverSearchInput = ({
  filedName,
  filterJobpostData,
  page,
  rowsPerPage,
  inputId,
  inputPlaceholder,
  setInputValue,
  setInputActiveValue,
  inputValue,
  isPopoverOpen,
  openPopover,
  setActivePopover,
}) => {
  return (
    <React.Fragment>
      <Popover
        isOpen={isPopoverOpen(filedName)}
        onClose={() => setActivePopover(null)}
        isLazy
        size={"sm"}
        closeOnBlur={false}
      >
        <PopoverTrigger>
          <IconButton
            size="sm"
            icon={
              <FilterListIcon
                style={{
                  cursor: "pointer",
                  fontSize: "20px",
                }}
              />
            }
            onClick={() => openPopover(filedName)}
            style={{ boxShadow: "none", background: "#fff" }}
          />
        </PopoverTrigger>
        <PopoverContent
          sx={{ zIndex: 1 }}
          boxSize={"auto"}
          style={{ boxShadow: "none" }}
        >
          <PopoverArrow />
          <PopoverBody style={{ width: "200px" }}>
            <InputGroup>
              <InputLeftElement
                children={
                  <img
                    src={Searchicon}
                    width="18px"
                    height="18px"
                    style={{ cursor: "pointer" }}
                  />
                }
                onClick={() => {
                  filterJobpostData(inputValue, page, rowsPerPage, inputId,true);
                }}
              />
              <Input
                type="text"
                id={inputId}
                value={inputValue}
                placeholder={inputPlaceholder}
                onChange={(e) => {
                  setInputValue(e.target.value);
                  setInputActiveValue({
                    name: inputId,
                    value: e.target.value,
                  });
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    filterJobpostData(inputValue, page, rowsPerPage, inputId ,true);
                  }
                }}
                style={{
                  height: "37px",
                  padding: "9px",
                  paddingLeft: "2.6rem",
                  color: "#7B9AAA",
                  fontFamily: "Roboto",
                  fontWeight: "500",
                  lineHeight: "19px",
                  fontSize: "15px",
                }}
              />
            </InputGroup>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </React.Fragment>
  );
};

export default PopoverSearchInput;
