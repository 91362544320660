import axios from "axios";
import config from "config";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

const EditSubmissionStatusModel = ({
  editSubmissionStatus,
  handleEditSubmissionStatusClose,
  submission,
  handleInputs,
  handleUpdateStatus,
  handleEditShow,
  SetEditSubmissionStatus,
}) => {
  const [allStatus, setAllStatus] = useState([]);

  const getCandidateApplicationAllStatus = async () => {
    await axios
      .get(`${config.apiUrl}/api/get_All_candidate_application_status`)
      .then((response) => {
        const statusData = response.data.data;
        setAllStatus(statusData);
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.error ||
            "Something happened wrong, please try again after sometime.",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      });
  };

  useEffect(() => {
    if (editSubmissionStatus) {
      getCandidateApplicationAllStatus();
    }
  }, [editSubmissionStatus]);
  return (
    <>
      <div className="model-box-view">
        <Modal
          show={editSubmissionStatus}
          onHide={handleEditSubmissionStatusClose}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title className="usertitle">
              Edit Application Status
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label
              style={{
                fontSize: "15px",
                fontWeight: "400",
                fontFamily: "Roboto",
                color: "#000000",
              }}
            >
              Status
            </label>
            <select
              class="form-select"
              aria-label="Default select example"
              name="status"
              value={submission.status}
              onChange={handleInputs}
            >
              <option disabled={true} value="">
                Application Status
              </option>
              {allStatus?.map((val) => (
                <>
                  <option value={val?.CandidateApplicationStatus}>
                    {val?.CandidateApplicationStatus}
                  </option>
                </>
              ))}
            </select>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="cancel"
              onClick={handleEditSubmissionStatusClose}
            >
              Cancel
            </button>
            <button
              className="save"
              onClick={() => {
                if (
                  submission?.status == "For Future Position" ||
                  submission?.status == "Pending"
                ) {
                  debugger
                  handleUpdateStatus();
                } else {
                  // handleSelectClientshow();
                  handleEditShow();
                  SetEditSubmissionStatus(false);
                }
              }}
            >
              Update
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default EditSubmissionStatusModel;
