import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "../../config";
// import { Cookies } from "react-cookie";

// const cookies = new Cookies();

// export const getTokenFromCookies = async () => {
//   return cookies.get('token') || localStorage.getItem('token');

// };

export const accountContactApi = createApi({
  reducerPath: "accountContactApi",
  baseQuery: fetchBaseQuery({
    baseUrl: config.apiUrl,
  }),

  endpoints: (builder) => ({
    addContactApi: builder.mutation({
      query: (body) => ({
        url: `/api/add_Account_contact`,
        method: "POST",
        body,
      }),
    }),
    updateContactApi: builder.mutation({
        query: ({contactId,data}) => ({
          url: `/api/update_Contact/${contactId}`,
          method: "PUT",
          body:data,
        }),
      }),
  }),
});

export const {
useAddContactApiMutation,
useUpdateContactApiMutation
} = accountContactApi;
